import axios from "axios";
import store from "../../../store";
import router from "../../../router";

const BASE_URL = process.env.VUE_APP_URL;
const handlerEnabled= {timeout: false};
var count = 1;
var wsReconnectTry = 0;

export default {

    
    async setupWebsocket({commit}){

      //if(!store.getters.user.test_completed){
        //Update User before connecting to Websocket
        await store.dispatch('fetchUserConfig');
      //}

      var user = store.getters.user;
      console.log('setupWebsocket', user);

      if(user.websocket_jwt){
        const socket = new WebSocket(`wss://lt45wwaxx2.execute-api.eu-central-1.amazonaws.com/production/?token=${ user.websocket_jwt }`);

        commit('setWebsocket', socket);

        var heartbeat; 

        socket.onopen = (event) => {
            console.log('setupWebsocket Connected', socket, event);
            wsReconnectTry = 0;

            //Heartbeat to keep Websocket open
            heartbeat = setInterval(() => {
              socket.send(JSON.stringify({action: 'ping'}));
            }, 60000);
          

        };

        socket.onerror = (event) => {
          console.log('setupWebsocket Error', socket, event);
        };

        socket.onclose = (event) => {
          console.log('setupWebsocket Close', socket, event);

          clearInterval(heartbeat)

          if(event.wasClean === true){
            //Reconnect Websocket after 1 second
            setTimeout(() => {
              store.dispatch('siteNotifications/setupWebsocket');
            }, 1000)
          }else if(wsReconnectTry < 10) {
            wsReconnectTry++;
            //Reconnect Websocket after 1 second
            setTimeout(() => {
              store.dispatch('siteNotifications/setupWebsocket');
            }, 1000)
          }
        };

        socket.onmessage = (event) => {
          console.log('setupWebsocket Message', socket, event);
          if(event.data){
            try {
              var socketData = JSON.parse(event.data);
              } catch (error) {
                console.log('error parsing socket data', error);
                return;
              }
            
            //Handle heartbeat response
            if(socketData.action == 'pong'){
              return;
            }

            //Set target
            let notificationTarget = (socketData.sender) ? `/membership-area/member-profile/profile?id=${socketData.sender.profileId}`:'';

            switch(socketData.type){
              case 'message':
                 notificationTarget = `/membership-area/messages/${socketData.data.conversation_id}`;
                break;
              case 'matches':
                notificationTarget = `/membership-area/matches`;
                break;
            }

            var notification = {
              id: count,
              seen: false,
              date: socketData.data.timestamp,
              type: socketData.type,
              data: socketData.data,
              target: notificationTarget
            }

            if( router.currentRoute.path == notification.target ){
              return;
            }

            if(socketData.sender){
              //Set Profile Picture
              const profilePicture = socketData.sender.profilePicture ? 
                socketData.sender.profilePicture
                : socketData.sender.gender == "male" ? 
                  require('@/assets/images/' + store.getters.assetsPath + 'silhouette-man.svg') 
                  : require('@/assets/images/' + store.getters.assetsPath + 'silhouette-woman.svg');
              
              notification.user = {
                  username: socketData.sender.name,
                  profileId: socketData.sender.profileId, //or contact-id
                  picture: profilePicture,
                  online: socketData.sender.online,
              }
            }

            count++; 

            commit('setNotifications', notification);
          }

        };

      }else{
        console.log('no websocket token');
      }

    },

    async updateNotification({commit}, data) {},

    async dismissNotification({commit}, id) {

        commit('removeNotification', id);
    },


}