export default {
  setNotifications(state, payload){
    console.log('setNotifications', payload);
    if(payload.length){
      payload.forEach( (notification) => {
        state.notifications.push(notification);  
      })
    }else {
      state.notifications.push(payload);  
    }
  },
  removeNotification(state, payload) {
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== payload
    );
  },
  setWebsocket(state, payload) {
    state.socketConnection = payload;
  },
  
};
