<template>
<div id="siteNotifications" v-if="notifications.length">
    <div 
        class="notification animate" 
        v-for="notification in notifications" 
        :key="notification.id" 
        :data-id="notification.id" 
        :ref="'notification_'+notification.id"
        @click="isMobile ? navigateTo(notification.target) : ''"
    >
        <div class="notification_wrapper" :class="[ notification.type ]">
            <!-- Message Notification -->
            <template v-if="notification.type == 'message'">
                <div class="profile_picture">
                    <img :src="notification.user.picture"/>
                </div>
                <div class="content_wrapper">
                    <h4>
                        <span 
                            class="status"
                            :class="{'online': notification.user.online}"
                        ></span>
                        {{notification.user.username}}
                    </h4>
                    <p>
                        <Icons :icon="'envelope'" :type="'solid'"/> 
                        <span v-if="notification.data.message">
                            {{ notification.data.message }}
                        </span>
                        <span v-else>
                            {{ $t($i18n.locale + '.keys.siteNotifications.newMessage') }}
                        </span>
                    </p>
                </div>
            </template>
            <!-- Visit Notification -->
            <template v-else-if="notification.type == 'visit'">
                <div class="profile_picture">
                    <img :src="notification.user.picture"/>
                </div>
                <div class="content_wrapper">
                    <h4>
                        <span 
                            class="status"
                            :class="{'online': notification.user.online}"
                        ></span>
                        {{notification.user.username}}
                    </h4>
                    <p>
                        <Icons :icon="'eye'" :type="'solid'"/> 
                        <span >
                            {{ $t($i18n.locale + '.keys.siteNotifications.visitMessage') }}
                        </span>
                    </p>
                </div>
            </template>
            <!-- Match Notification -->
            <template v-else-if="notification.type == 'match'">
                <div class="profile_picture">
                    <img :src="notification.user.picture"/>
                </div>
                <div class="content_wrapper">
                    <h4>
                        <span 
                            class="status"
                            :class="{'online': notification.user.online}"
                        ></span>
                        {{notification.user.username}}
                    </h4>
                    <p>
                        <Icons :icon="'puzzle'" :type="'solid'"/> 
                        <span >
                            {{ $t($i18n.locale + '.keys.siteNotifications.matchMessage') }}
                        </span>
                    </p>
                </div>
            </template>
            <!-- Matches Notification -->
            <template v-else-if="notification.type == 'matches'">
                <div class="content_wrapper">
                    <h4>
                        <Icons :icon="'puzzle'" :type="'solid'"/> 
                        <span>
                            {{ $t($i18n.locale + '.keys.siteNotifications.matchesMessage', {count: notification.data.count}) }} 
                            <br>(count: {{notification.data.count}})
                        </span>
                    </h4>
                </div>
            </template>
            
            <div class="dismiss_notification" @click.stop.prevent="dismissNotification(notification.id)">
                <i class="fa fa-times"></i>
            </div>
        </div>
        <div class="link_wrapper" v-if="!isMobile" @click="navigateTo(notification.target, notification.id)">
            <button v-if="notification.type == 'message'">
                {{ $t($i18n.locale + '.keys.siteNotifications.messageButton') }}
            </button>
            <button v-else-if="notification.type == 'matches'">
                {{ $t($i18n.locale + '.keys.siteNotifications.matchesButton') }}
            </button>
            <button v-else>
                {{ $t($i18n.locale + '.keys.siteNotifications.profileButton') }}
            </button>
        </div>
    </div>
    
</div>
</template>

<script>

export default {

    name: "SiteNotifications",
    data() {
        return {

        };
    },
    computed: {
      notifications(){
        return this.$store.getters['siteNotifications/getNotfications'];
      },  
      isMobile() {
        return this.$store.getters.isMobile;
      }
    },
    updated () {
        console.log('SiteNotifications updated', this.notifications.length, this.$refs);
        
        this.notifications.forEach((notification) => {
            if(!notification.timer) {
                notification.timer = setTimeout(() => {
                    //this.dismissNotification(notification.id);
                    notification.seen = true;
                    var element = document.querySelector('.notification[data-id="' + notification.id + '"]');
                    element.classList.add('removed');
                    element.classList.remove('animate');

                    this.$gtm.trackEvent({
                        event: 'NOTFICATION',
                        category: 'FEATURES',
                        action: 'timeout',
                        label: notification.target
                    });

                    this.dismissNotification(notification.id)
                    
                }, 180000)
            }
        })
        
    },
    created() {
        console.log('SiteNotifications mounted');
        if( !this.$store.getters['siteNotifications/getSocketConnection'] ) {
            this.$store.dispatch('siteNotifications/setupWebsocket')
        }
    },
    methods: {
        navigateTo(target, id){
            
            this.$gtm.trackEvent({
                event: 'NOTFICATION',
                category: 'FEATURES',
                action: 'navigate',
                label: target
            });

            if(target) this.$router.push(target);
            
            this.dismissNotification(id);

        },
        dismissNotification(id) {

            var element = document.querySelector('.notification[data-id="' + id + '"]');
            var notification = this.notifications.filter(notification => notification.id == id)[0];
            clearTimeout(notification.timer);
            
            console.log('dismissNotification', id, element, notification);
            element.classList.add('removed');

            this.$gtm.trackEvent({
                event: 'NOTFICATION',
                category: 'FEATURES',
                action: 'dismiss',
                label: notification.target
            });

            setTimeout(() => {
                this.$store.dispatch('siteNotifications/dismissNotification', id);    
            }, 500)
            
        }
    }

}

</script>