<template>
  <div id="app" v-if="languageFetched">
    <ChangeLanguage></ChangeLanguage>

    <div
      class="editBtnWrapper"
      :class="{ editingMode: $store.getters.editable }"
      v-if="$store.getters.userRole.includes('ROLE_PAGE_TRANSLATOR')"
    >
      <button
        :class="{
          superadmin: $store.getters.userRole.includes('ROLE_PAGE_TRANSLATOR'),
        }"
        @click="$store.dispatch('changeEditable')"
        class="languageEditingBtn"
      >
        Enable editing
      </button>
    </div>

    <SiteNotifications v-if="showNotifications"/>
    <router-view />

    <DomainMoveModal 
      v-if="showDomainMoveModal" 
      @close="closeDomainMoveModal"   
    ></DomainMoveModal>
  </div>
</template>

<script>
import ChangeLanguage from "./components/ChangeLanguage.vue";
import DomainMoveModal from "./components/generic/DomainMoveModal.vue";
import SiteNotifications from "./components/generic/SiteNotifications.vue";

 import axios from "axios";
 import jwt from "jsonwebtoken";

export default {
  name: "app",
  components: {
    ChangeLanguage,
    DomainMoveModal,
    SiteNotifications
  },

  data() {
    return {
      languageFetched: true,
      availableDomains: ["com", "de", "ch", "at"],
      url: process.env.VUE_APP_URL,
      gtm: {
        matches: false,
        picture: false,
        premium: false,
        uid: false,
      },
      showDomainMoveModal: false
    };
  },

  computed: {
    skipAuth(){
      if(this.$route.path.indexOf('/resetPassword') != -1) return true;
      else return false;
    },
    showNotifications() {
      if(
        this.$route.path.indexOf('membership-area') == -1 &&
        this.$route.path.indexOf('premium-plans/plans') == -1 
      ) return false;
      else return true;
    }
  },
  mounted() {
    if(this.skipAuth) return true;
    if(!this.$store.getters.isAuthenticated){

      this.$store.dispatch("logout")
      //window.location.href = 'https://' + host +'.'+ this.$store.getters.getCountryDomainLanding
      //window.location.href = `${process.env.VUE_APP_LANDING_URL}${this.$store.getters.getCountryDomainLanding}`;
    }

    //Domain move modal
    var currentURL = new window.URL(window.location.href)
    console.log('Domain move modal', currentURL)

    if(window.location.hostname.includes('au.loveat50.com') && currentURL.searchParams.has('com_au')){
      this.showDomainMoveModal = true;
    }
  },
  updated(){
    //set meta title
    var pageTitle = this.$store.getters.productName;
    if(this.$route.path.indexOf('/membership-area/messages') != -1) {
      pageTitle += ' - ' + this.$t(this.$i18n.locale).keys.membershipArea.membershipHeader.messages;
    }
    else if(this.$route.path == '/membership-area/matches'){
      pageTitle += ' - ' + this.$t(this.$i18n.locale).keys.membershipArea.membershipHeader.matches;
    }
    else if(this.$route.path == '/membership-area/visitors'){
      pageTitle += ' - ' + this.$t(this.$i18n.locale).keys.membershipArea.membershipHeader.visitors;
    }
    else if(this.$route.path == '/membership-area/meetings'){
      pageTitle += ' - ' + this.$t(this.$i18n.locale).keys.membershipArea.membershipHeader.haveYouMet;
    }
    else if(this.$route.path == '/membership-area/home'){
      pageTitle += ' - ' + this.$t(this.$i18n.locale).keys.membershipArea.membershipHeader.home;
    }
    else if(this.$route.path == '/membership-area/user-profile/profile'){
      pageTitle += ' - ' + this.$t(this.$i18n.locale).keys.membershipArea.membershipHeader.profile;
    }
    else if(this.$route.path == '/membership-area/matches-preferences'){
      pageTitle += ' - ' + this.$t(this.$i18n.locale).keys.membershipArea.membershipHeader.preferences;
    }
    else if(this.$route.path == '/membership-area/my-account'){
      pageTitle += ' - ' + this.$t(this.$i18n.locale).keys.membershipArea.membershipHeader.myAccount;
    }

    document.querySelector('title').text = pageTitle;
  },
  async created(){

    console.log('async created');

    //SET USER GENDER
    console.log('//SET USER GENDER');

    var user_data = await axios.get(this.url + `/api/profile/my_picture`);

    this.$store.commit('set_user_gender', user_data.data.data.gender)
    this.$store.commit('set_user_profilePic', user_data.data.data.public_path)

    console.log('UPDATED USER', this.$store.getters.user)

    //this.$store.dispatch('loadTerms', this.$store.getters.selectedLanguage);

    console.log('SITE UPDATED');

    //Event handler for global mobile switch
    window.addEventListener("resize", async () => {
        if (window.innerWidth < 992) {
            this.$store.commit('set_isMobile', true)
          }else{
            this.$store.commit('set_isMobile', false)
          }
    });
    window.dispatchEvent(new Event('resize'));

    //Set WL Logos
    
    if(host == 'loveover50s'){
      let logos = {
        desktop: require('@/assets/images/loveover50s/logo-desktop.svg'),
        mobile: require('@/assets/images/loveover50s/logo-mobile.svg')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', 'LoverOver50s');

    }else if(host == 'liebeuber50'){
        let logos = {
        desktop: require('@/assets/images/liebeuber50/logo-desktop.svg'),
        mobile: require('@/assets/images/liebeuber50/logo-mobile.svg')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', 'LiebeÜber50');
    }else if(host == '60slove'){
        let logos = {
        desktop: require('@/assets/images/60slove/logo-desktop.svg'),
        mobile: require('@/assets/images/60slove/logo-mobile.svg')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', '60sLove');
      //WL assets path
      this.$store.commit('set_assetsPath', '60slove/');
      // WL favicon
      document.querySelector('#favicon').href = '/favicon60slove.svg';

    }else if(host == '40smeet'){
        let logos = {
        desktop: require('@/assets/images/40smeet/logo-desktop.svg'),
        mobile: require('@/assets/images/40smeet/logo-mobile.svg')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', '40sMeet');
      //WL assets path
      this.$store.commit('set_assetsPath', '40smeet/');
      // WL favicon
      document.querySelector('#favicon').href = '/favicon40smeet.svg';
    }else if(host == 'our50s'){
        let logos = {
        desktop: require('@/assets/images/our50s/logo-desktop.svg'),
        mobile: require('@/assets/images/our50s/logo-mobile.svg')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', 'Our50s');
      //WL assets path
      this.$store.commit('set_assetsPath', 'our50s/');
      // WL favicon
      document.querySelector('#favicon').href = '/faviconOur50s.svg';
    }else if(host == 'loveat50'){
        let logos = {
        desktop: require('@/assets/images/loveat50/logo-desktop.svg'),
        mobile: require('@/assets/images/loveat50/logo-mobile.svg')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', 'LoveAt50');
      //WL assets path
      this.$store.commit('set_assetsPath', 'loveat50/');
      // WL favicon
      document.querySelector('#favicon').href = '/faviconLoveat50.svg';

    }else if(host == 'xn--liebe50-r2a'){
        let logos = {
        desktop: require('@/assets/images/liebeue50/logo-desktop.svg'),
        mobile: require('@/assets/images/liebeue50/logo-mobile.svg')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', 'LiebeÜ50');
      //WL assets path
      this.$store.commit('set_assetsPath', 'liebeue50/');
      // WL favicon
      document.querySelector('#favicon').href = '/faviconLiebeue50.svg';

    }else if(host == '40slove'){
      let logos = {
      desktop: require('@/assets/images/40slove/logo-desktop.svg'),
      mobile: require('@/assets/images/40slove/logo-mobile.svg')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', '40sLove');
      //WL assets path
      this.$store.commit('set_assetsPath', '40slove/');
      // WL favicon
      document.querySelector('#favicon').href = '/favicon40slove.svg';

    }else if(host == 'xn--liebe40-r2a'){
        let logos = {
        desktop: require('@/assets/images/liebeue40/logo-desktop.svg'),
        mobile: require('@/assets/images/liebeue40/logo-mobile.svg')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', 'LiebeÜ40');
      //WL assets path
      this.$store.commit('set_assetsPath', 'liebeue40/');
      // WL favicon
      document.querySelector('#favicon').href = '/faviconLiebeue40.svg';

    }else if(host == 'liebeab40'){
        let logos = {
        desktop: require('@/assets/images/liebeab40/logo-desktop.svg'),
        mobile: require('@/assets/images/liebeab40/logo-mobile.svg')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', 'Liebeab40');
      //WL assets path
      this.$store.commit('set_assetsPath', 'liebeab40/');
      // WL favicon
      document.querySelector('#favicon').href = '/favicon40smeet.svg';

    }else if(host == 'loveat40'){
        let logos = {
        desktop: require('@/assets/images/loveat40/logo-desktop.svg'),
        mobile: require('@/assets/images/loveat40/logo-mobile.svg')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', 'Loveat40');
      //WL assets path
      this.$store.commit('set_assetsPath', 'loveat40/');
      // WL favicon
      document.querySelector('#favicon').href = '/favicon40smeet.svg';


    }else if(host == 'xn--liebeber40-eeb'){
        let logos = {
        desktop: require('@/assets/images/liebeueber40/main-logo-desktop.svg'),
        mobile: require('@/assets/images/liebeueber40/main-logo-desktop.svg')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', 'LiebeÜber40');
      //WL assets path
      //this.$store.commit('set_assetsPath', 'liebeueber40/');
      // WL favicon
      //document.querySelector('#favicon').href = '/faviconLiebeue50.svg';

    }else if(host == 'xn--liebeber50-eeb'){
        let logos = {
        desktop: require('@/assets/images/liebeueber50/main-logo-desktop.svg'),
        mobile: require('@/assets/images/liebeueber50/main-logo-desktop.svg')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', 'LiebeÜber50');
      //WL assets path
      //this.$store.commit('set_assetsPath', 'liebeueber50/');
      // WL favicon
      //document.querySelector('#favicon').href = '/faviconLiebeue50.svg';

    }else if(host == 'xn--liebeber60-eeb'){
        let logos = {
        desktop: require('@/assets/images/liebeueber60/main-logo-desktop.svg'),
        mobile: require('@/assets/images/liebeueber60/main-logo-desktop.svg')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', 'LiebeÜber60');
      //WL assets path
      this.$store.commit('set_assetsPath', 'liebeueber60/');
      // WL favicon
      document.querySelector('#favicon').href = '/faviconLiebeueber60.svg';

    
    }else if(host == 'amourplusde40'){
        let logos = {
        desktop: require('@/assets/images/amourplusde40/logo-desktop.svg'),
        mobile: require('@/assets/images/amourplusde40/logo-mobile.svg')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', 'Amourplusde40');
      //WL assets path
      this.$store.commit('set_assetsPath', 'amourplusde40/');
      // WL favicon
      document.querySelector('#favicon').href = '/faviconAmourplusde40.svg';
    }else if(host == 'amourplusde50'){
        let logos = {
        desktop: require('@/assets/images/amourplusde50/logo-desktop.svg'),
        mobile: require('@/assets/images/amourplusde50/logo-mobile.svg')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', 'Amourplusde50');
      //WL assets path
      this.$store.commit('set_assetsPath', 'amourplusde50/');
      // WL favicon
      document.querySelector('#favicon').href = '/faviconAmourplusde50.svg';
    }else if(host == 'amourplusde60'){
        let logos = {
        desktop: require('@/assets/images/amourplusde60/logo-desktop.svg'),
        mobile: require('@/assets/images/amourplusde60/logo-mobile.svg')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', 'Amourplusde60');
      //WL assets path
      this.$store.commit('set_assetsPath', 'amourplusde60/');
      // WL favicon
      document.querySelector('#favicon').href = '/faviconAmourplusde60.svg';
    }

    //Set Support Email address
    this.$store.dispatch('updateSupportEmail');

    let token = localStorage.getItem("token");
    let decoded = jwt.decode(token, { complete: true });

    document.querySelector('title').text = this.$store.getters.productName;

//this.$gtm.enable(true);
    if(decoded){
      window.dataLayer.push({'uid': decoded.payload.user_id});
      this.gtm.uid = true;
      this.enableGTM();

      if(decoded.payload.roles.includes('ROLE_PREMIUM')){
        window.dataLayer.push({'is_premium': true});
        this.gtm.premium = true;
        this.enableGTM();
      }else{
        window.dataLayer.push({'is_premium': false});
        this.gtm.premium = true;
        this.enableGTM();
      }

    await this.$store.dispatch('fetchMatches').then((res)=>{
      console.log('GTM fetchMatches', res);
      window.dataLayer.push({'matchCount': this.$store.getters.allMatchesCount});
      this.gtm.matches = true;
      this.enableGTM();
    });

    axios
    .get(this.url + `/api/profile/my_picture`)
    .then(response => {
      //add to GTM tracking
        var hasPicture = false;
        if(response.data.data.public_path) hasPicture = true;
        window.dataLayer.push({'hasPicture': hasPicture});
        this.gtm.picture = true;
        this.enableGTM();
    });

   }

    //GTM fallback
    setTimeout(() => {
      console.log('GTM fallback');
      if(!this.$gtm.enabled()) this.$gtm.enable(true);
    },2000);

   },
  async beforeMount() {
    if(!this.skipAuth){
      //Load site specific data from api
      this.$store.dispatch('fetchSiteConfig');

      //Load user specific data from api
      await this.$store.dispatch('fetchUserConfig');

      this.$store.dispatch("checkCompleted");
      this.$store.dispatch("getLocales");
    }
    this.changeLanguage();

  },
  methods: {
    closeDomainMoveModal() {
      this.showDomainMoveModal = false;
      //Remove modal parameter
      var newURL = new window.URL(window.location.href);
        newURL.searchParams.delete('com_au');
        window.history.replaceState({}, "", newURL.href);
    },
    enableGTM() {
      console.log('enableGTM');
      if(this.gtm.matches && this.gtm.picture && this.gtm.premium && this.gtm.uid && !this.$gtm.enabled()){
         this.$gtm.enable(true);
         let gScript_init = document.createElement('script')
        gScript_init.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?l=gDataLayer')
        document.head.appendChild(gScript_init)

        let gScript_body = document.createElement('script')
        gScript_body.text = "window.gDataLayer = window.gDataLayer || [];function gtag(){gDataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-473491805');"
        document.head.appendChild(gScript_body)
      }
    },
    logout: () => {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },

    changeLanguage() {

      let localeToSet = 'en';

      /*
      let token = localStorage.getItem("token");

      if(token){
        let decoded = jwt.decode(token, { complete: true });
        localeToSet = decoded.payload.locale;
        console.log('locale from token')
      }else{
        console.log('locale from url')
        let url = window.location.origin.split(".");
        let domain = url[url.length - 1];
        if (domain == "de" || domain == "ch" || domain == "at") {
          localeToSet = "de";
        }else if (domain == "cz") {
          localeToSet = "cz";
        }else if (domain == "pl") {
          localeToSet = "pl";
        } else {
          localeToSet = "en";
        }

      }
      */

      console.log('user', this.$store.getters.user);

      localeToSet = this.$store.getters.user.locale;

// this.availableDomains.contains(domain)

      // if (jwt.decode(token) == null) {
      //   localeToSet = "en";
      // }
      console.log("!!!!", localeToSet);
      this.$store
        .dispatch("changeLanguage", {
          locale: localeToSet,
          keys: this.$t(this.$i18n.locale),
        })
        .then(() => {
          this.languageFetched = true;
          this.$store.dispatch('loadTerms', {lang: this.$store.getters.selectedLanguage, vm: this});
        });
    },
  },
};
</script>

<style lang="scss"></style>
